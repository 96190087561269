.slideItemNew {
    /* max-height: 80vh; */
    min-height: 30vh;
}

.slideItemNew img {
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.slick-next::before,
.slick-prev::before {
    font-size: 0px !important;
}


.slider {
    background-color: white;
    position: relative;
}

.slider .arrow {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: transparent;
    padding: 10px;
    line-height: 15px;
    border-radius: 50px;
    /* box-shadow: 0px 5px 10px rgba(0,0,0,0.1); */
}

.slider .arrow2 {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: transparent;
    padding: 10px;
    line-height: 15px;
    border-radius: 50px;
    /* box-shadow: 0px 5px 10px rgba(0,0,0,0.1); */
}

.slider .arrow3 {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: transparent;
    padding: 10px;
    line-height: 15px;
    border-radius: 50px;
    /* box-shadow: 0px 5px 10px rgba(0,0,0,0.1); */
}

.slider .arrow i {
    font-size: 18px !important;
}

.slider .arrow3 i {
    font-size: 21px !important;
    color: black;
}

.slider .arrow2 i {
    font-size: 21px !important;
    color: black;
}

.slider .arrow.left {
    left: 0px;
}

.slider .arrow.right {
    right: 20px;
}

.slider .arrow2.left {
    /* left: 20px; */
    left: -4.5vw;
}

.slider .arrow2.right {
    right: 10 !important;
}

@media screen and (max-width: 479px) {
    .slider .arrow2.right {
        right: 10 !important;
    }
}

@media (max-width: 479px) {
    .slider .arrow2 {
        /* left: -25px; */
        display: none;
    }

    .slider .arrow2 {
        /* right: -44px; */
        display: none;
    }
}

@media (min-width: 768px) {
    .slider .arrow2.left {
        /* left: -25px; */
        left: -3.5vw;
    }

    .slider .arrow2.right {
        /* right: -44px; */
        right: -2.7vw;
    }
}

.slider .arrow3.left {
    /* left: 20px; */
    left: -2.7vw;
}

.slider .arrow3.right {
    right: 20px;
}

@media (min-width: 768px) {
    .slider .arrow3.left {
        /* left: -25px; */
        left: -2.7vw;
    }

    .slider .arrow3.right {
        /* right: -44px; */
        right: -2.7vw;
    }
}

.slider .arrow:active,
.slider .arrow2:active {
    transform: translateY(-50%) scale(0.9);
}

.slider .slideScroller {
    overflow: hidden;
    width: 100%;
    scroll-behavior: smooth;
}

.slider .slideScroller .slideContent {
    /* margin-left: 18px; */
    display: flex;
}

.slider .slideScroller .slideContent .slideItem>img {
    height: 100%;
    width: 100%;
    /* -o-object-fit: cover;
    object-fit: cover; */
    transition: all 1s;
}

@media screen and (min-width:1500px) {

    .slider .slideScroller .slideContent .slideItem>img {
        height: 57%;
        width: 100%;
        transition: all 1s;
    }

}