.BioDetailDescriptionPortfolio {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  color: #373530;
  line-height: 1.4;
  font-weight: 500;
}

.BioDetailDescriptionPortfolio > h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.5rem;
  color: #373530;
  line-height: 1.4;
  font-weight: 500;
}

.BioDetailDescriptionPortfolio > p {
  margin: 0px;
}
.right_content_bio {
  width: 75%;
  padding-left: 1.5vw;
}
.mid_content_bio {
  width: 25%;
}
.error {
  color: red;
}
.main-container-awards {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.logoutImage {
  width: 20px;
}
.personalWorksMainContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.awardContainer {
  margin: 0px 15px 15px 15px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 20px;
  height: fit-content;
}

.h4CommonStyle {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0px;
}

.containerModal2 {
  width: 90vw;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
.awardLinkStyle {
  color: #d16a27;
  font-size: 15px;
  margin-top: 15px;
}

.awardLinkStyle a {
  color: #d16a27;
  font-size: 14px;
  text-decoration: underline;
}
.awardsWithImage {
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: 0.5fr 1fr;
  gap: 10px;
}

@media screen and (max-width: 1340px) {
  .right_content_bio {
    width: 60%;
  }
  .mid_content_bio {
    width: 40%;
  }
}
@media screen and (max-width: 991px) {
  .BioDetailDescriptionPortfolio > h1,
  h2,
  a,
  h3,
  h4,
  h5,
  p {
    font-size: 16px !important;
    line-height: 1.5;
  }
  .personalWorksMainContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .main-container-awards {
    width: 100%;
    row-gap: 10px;
  }
}
@media screen and (max-width: 576px) {
  .awardsWithImage {
    grid-template-columns: none;
    grid-template-rows: auto;
    row-gap: 10px;
  }
  .mobMenu a {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
  .carouselArrow {
    width: 10%;
  }
  .right_content_bio {
    width: 100%;
    padding-left: 0;
  }

  .mid_content_bio {
    width: 100%;
  }
  .bioMainImage {
    width: 40%;
  }
  .personalWorksMainContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
