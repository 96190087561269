.myPopup{
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.myPopup>.closeBack{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.myPopup>.myPopupCard{
    position: relative;
    background-color: black;
    padding: 15px;
    border-radius: 10px;
    color: white;
    z-index: 1;
    font-weight: bold;
    font-family: "Roboto2";
    text-align: center;
}
.myPopup>.myPopupCard>.closeBtn{
    position: absolute;
    top: 20px;
    right: 25px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.myPopup>.myPopupCard>.closeBtn:hover::after,.myPopup>.myPopupCard>.closeBtn:hover::before{
    border-color: red;
}
.myPopup>.myPopupCard>.closeBtn::after,.myPopup>.myPopupCard>.closeBtn::before{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: solid white;
    border-width: 2px 2px 0px 0px;
    top: 5px;
}
.myPopup>.myPopupCard>.closeBtn::before{
    transform: rotate(45deg);
    left: -7.5px;
}
.myPopup>.myPopupCard>.closeBtn::after{
    transform: rotate(-135deg);
    right: -7.5px;
}